// Copyright © 2021 Move Closer

import { SizeRegistry } from '../../composables'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export enum SelectSizeMap {
  Medium = 'medium',
}

export const sizeRegistry: SizeRegistry<string> = {
  [SelectSizeMap.Medium]: ''
}
